'use client';
import terrataAxios from '@/lib/terrataAxios';
import { AUTH_CONSTANTS } from '@/lib/useAuth';
import { useMounted } from '@mantine/hooks';
import { use } from 'react';
import { datadogRum } from '@datadog/browser-rum';

export const dynamic = 'force-dynamic';

function getAccessToken() {
  try {
    return localStorage?.getItem(AUTH_CONSTANTS.ACCESS_TOKEN) || '';
  } catch (e) {
    return '';
  }
}

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
export const waitWhileAuthenticated = async (ms?: number) => {
  await wait(ms || 1000);

  try {
    if (getAccessToken() !== '') {
      // make a call to the user end point to test that the token is still valid
      const resp = await terrataAxios.get('/users/info/');
      datadogRum.setUser({ email: resp?.data?.email });
      return true;
    } else {
      return false;
    }
  } catch {
    return false;
  }
};

// export const PROTECTED_PATHS = ['/', '/change-password'];

export const Loading = ({ promise, children }: any) => {
  const isMounted = useMounted();

  let isAuthenticated = use<any>(promise());

  if (!isMounted) {
    return null;
  }

  if (
    isMounted &&
    !isAuthenticated //&&
    // PROTECTED_PATHS.includes(window.location.pathname)
  ) {
    window.location.href = '/login';
    return null;
  }
  return isMounted && children;
};
